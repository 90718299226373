import AuthService from 'sools-auth-hedera/AuthService'
import NotificationsService from 'sools-hedera/components/notifications/NotificationsService'
import global from 'sools-hedera/global'
import Navigator from 'sools-hedera/routing/Navigator'
import MenuService from 'sools-core-hedera/MenuService'
import config from './config'

import Loader from './components/Loader'
import Interface from './components/Interface'
import { buildCollections } from 'sools-modeling-client'

Object.assign(global, {
  auth: new AuthService(config.api.url),
  notifications: new NotificationsService(),
  navigator: new Navigator(),
  menu: new MenuService(),
})


buildCollections(config.api.url)

Object.assign(global.components, {
  Loader,
  Interface,
})

global.menu.links.push({
  label: 'Home',
  class: 'fa-solid fa-house',
  url: '/',
})

export default global
