import Flex from '../../../style/displays/Flex'
import Base from './Base'
import Cell from './Cell'
import Array from 'sools-modeling/types/Array'

export default (
  class Row extends Base {

  }
    .define({
      name: 'Row',
    })
    .properties({
      cells: Array.of(Cell),
      style: Flex,
    })
)