import Component from 'sools-hedera/Component'
import template from './template.html'
import context from 'sools-core-client/context'
import ObjectState from 'sools-modeling/stating/ObjectState'
import './style.scss'

const applyStates = (targetStates, statesPatch) => {
  Object.entries(statesPatch)
    .forEach(([k, patch]) => {
      const target = targetStates[k]
      Object.assign(target, patch)
      if (patch.states) {
        applyStates(target.states, patch.states)
      }
    })
}


export default (
	class RootObjectForm extends Component {
  constructor(values = {}) {
    super()
    Object.assign(this, values)
  }

  async onInit() {
    this.on('propertyChanged:value', this.b(this.onValueChanged))
    this.onValueChanged()
  }

  async onValueChanged() {

    const { value } = this
    if (!value) {
      this.state = null
      return
    }
    const type = value.constructor
    this.state = new ObjectState({
      property: {
        name: type.definition.name,
        type,
      },
      value,
      required: true,
    })

    await this.state.validate(true)
  }
}
  .define({
    name: 'root-object-fieldset',
    template,
  })
  .properties({
    value: 'any',
    type: 'any',
    mode: 'any',
    state: 'any',
  })



)