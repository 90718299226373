import Component from '../../../Component'
import template from "./template.html"
import ModalContainer from '../ModalContainer'
import "./style.scss";


export default (
	class Modal extends Component {
  constructor() {
    super()
    this.isOpened = false
  }

  show() {
    ModalContainer.instance.show(this)
    this.isOpened = true
  }

  close() {
    this.isOpened = false
    ModalContainer.instance.close()
  }
}
  .define({
    name: "mo-dal",
    template,
  })
  .properties({
    title: 'any',
    isOpened: 'any',
  })

)