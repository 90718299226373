import mixer from 'sools-core/mixer'
import BaseHoldable from './BaseHoldable'

export default mixer.mixin([BaseHoldable], (base) => {
  return class HoldableFragment extends base {
    hold(reference) {
      this.owner.hold(reference)
    }

    release(reference) {
      this.owner.release(reference)
    }
  }
})
  .define()
