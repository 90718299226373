import ViewModel from './ViewModel'

export default (
	class Vars extends ViewModel {
  constructor() {
    super()
    this.bindingFunctions = []
  }
  destroy() {
    this.bindingFunctions.forEach((b) => b.destroy())
    super.destroy()
  }
}
  .define()
)