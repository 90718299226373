import { Model } from 'sools-modeling/types'
import ModelComponent from '../ModelComponent'
import template from './template.html'

import './style.scss'


export default (
	class Row extends ModelComponent {

}
  .define({
    name: 'model-row',
    type: 'row',
    template
  })
  .register(Model, 'row')

)