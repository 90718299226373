import Value from '../Value'

export default (
	class Size extends Value {

}
  .define({
    name: 'CssSize',
    abstract: true,
  })
)