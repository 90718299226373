import String from './String.js'

export default (
	class Password extends String {

}

  .define({
    name: 'Password',
  })

)