import 'sools-modeling-client/setup'
import hederaSetup from 'sools-hedera/setup'
import Holder from 'sools-modeling-client/mixins/holding/Holder'
import setup from 'sools-modeling/setup'
import StateMixin from './StateMixin'

setup.state.push(StateMixin)

hederaSetup.base.push(Holder)

export { default as routing } from './routing/setup'
