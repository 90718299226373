import mixer from 'sools-core/mixer.js'
import Type from './Type.js'
import Bool from './primitives/Bool.js'
import Real from './Real.js'
const NativeObject = Object

import setup from '../setup.js'
import ObjectState from '../stating/ObjectState.js'
const config = setup.object


export default (
  class Object extends mixer.extends(Real, [...config.before]) {
    static equals(o1, o2) {
      if (o1.constructor !== o2.constructor) {
        return false
      }
      /*
      if (o1.constructor !== this) {
        return o1.constructor.equals(o1, o2)
      }
      */
      for (const p of o1.constructor.properties) {
        if (!p.type.equals(o1[p.name], o2[p.name])) {
          return false
        }
      }
      return true
    }

    shadowClone() {
      const json = this.toJSON()
      const shadowClone = new this.constructor()
      NativeObject.assign(shadowClone, json)
      return shadowClone
    }
  }
    .define({
      name: 'object',
      abstract: true,
    })
    .methods({
      is: [[Type], Bool]
    })
    .state(ObjectState)
)