import Virtual from './Virtual.js'

export default (
	class Type extends Virtual {
  static build(value) {
    return value
  }

  static toJSON(value) {
    return value
  }
}
  .define({
    name: 'type',
  })


)