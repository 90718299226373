
import Virtual from './Virtual.js'

export default (
	class This extends Virtual {
  static getType(ownerType) {
    return ownerType
  }
}
  .define({
    name: 'This',
  })



)