import setup from '../setup.js'
import mixer from 'sools-core/mixer.js'
import Destroyable from 'sools-core/mixins/Destroyable.js'
import BaseModels from './BaseModels.js'

export default (
	class Models extends mixer.extends(BaseModels, [Destroyable, ...setup.models.before]) {

}
  .define()

)