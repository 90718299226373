
import Holdable from './mixins/holding/Holdable'
import HoldableFragment from './mixins/holding/HoldableFragment'
import Holder from './mixins/holding/Holder'
import ArrayHolder from './mixins/holding/ArrayHolder'
import Transformable from './mixins/transforming/Transformable'
import Referenceable from './mixins/Referenceable'
import ClientHasMany from './mixins/ClientHasMany'
import SingleInstance from './mixins/SingleInstance'
import ClientQueryResult from './mixins/ClientQueryResult'
import context from 'sools-core-client/context'
import ClientModel from './mixins/ClientModel'
import setup from 'sools-modeling/setup'

const { object, model, arrayAssociation, baseModels, models, hasMany, queryResult } = setup


model.before.push(Holdable, Holder, Transformable, SingleInstance)

baseModels.before.push(ArrayHolder, Referenceable)
models.before.push(Holdable, ClientModel)
arrayAssociation.before.push(HoldableFragment)
hasMany.before.push(ClientHasMany)
queryResult.before.push(ClientQueryResult)

setup.getArgs = (args) => {
  if (args[0] !== context) {
    args.unshift(context)
  }
  return args
}