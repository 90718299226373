import Event from 'sools-core/types/Event'

import { createFunction } from '../../utils'

export const processFunctionString = (string) => {
  let sanitized = string
  const paths = string.match(/((?:[\w|$|?]+\.@?[\w|$|.|@|?]+))/g) || []
  //.filter((p) => p.indexOf('@') !== -1)
  paths
    .forEach((p) => {
      sanitized = sanitized.replace(p, p.replace(/@/g, ''))
    })

  return {
    sanitized,
    paths,
  }
}


export const onBindingGetProperty = new Event()
export const onBindingDestroyed = new Event()

export default (
  class BindingFunction {
    constructor(functionString, variables, callback) {
      this.functionString = functionString
      this.variables = variables
      this.callback = callback
      this.listeners = []
      this.holdables = []
      const { sanitized, paths } = processFunctionString(functionString)
      this.paths = paths
      const vars = {
        ...variables
      }
      delete vars.this
      this.sanitizedFunctionString = sanitized
      this.function = createFunction('return ' + sanitized, vars)
    }

    async getValue() {
      const thisArg = this.variables.this
      const value = await this.function.call(thisArg)
      return value
    }

    async update(trigger = true) {
      this.destroy()
      this.listeners = []
      this.paths.forEach((path) => {
        const segments = path.split('.')
        let value = this.variables
        segments.forEach((segment) => {
          if (!value) { return }

          const propertyName = segment.replace(/[@?]+/g, '')
          if (segment.startsWith('@')) {
            if (!value.on) {
              console.log(path, { value })
              throw new Error()
            }
            const listener = value.on(`propertyChanged:${propertyName}`, async () => {
              await this.update()
            })
            this.listeners.push(listener)
          }

          value = value[propertyName]
          if (value) {
            if (segment.endsWith('@')) {
              const listener = value.on('changed', async () => {
                await this.update()
              })
              this.listeners.push(listener)
            }
            onBindingGetProperty.trigger(this, value)
          }


        })
      })
      if (trigger) {
        const value = await this.getValue()
        await this.callback(value)
        return value
      }
    }

    destroy() {
      this.listeners.forEach((l) => l.remove())
      onBindingDestroyed.trigger(this)
    }
  }
)