import Component from '../../../Component'
import template from './template.html'
import './style.scss'

export default (
  class ShortCuts extends Component { }
    .define({
      name: 'short-cuts',
      template
    })
)