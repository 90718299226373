import { Number } from 'sools-modeling/types'
import Size from './Size'

export default (
  class PixelSize extends Size {
  
    constructor(value) {
      super({ value })
    }

    toString() {
      return `${this.value}px`
    }
  }
    .define({
      name: 'px',
    })
    .properties({
      value: {
        type: Number,
        state: {
          required: true,
        }
      }
    })
)