import Size from './Size'

export default (
	class AutoSize extends Size {
  toString() {
    return 'auto'
  }
}
  .define({
    name: 'auto',
  })
)