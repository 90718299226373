import Angle from './Angle'
import Degree from 'sools-modeling/types/primitives/numbers/Degree'

export default (
	class Degree extends Angle {
  toString() {
    return `${this.value}deg`
  }
}
  .define({
    name: 'Degree',
  })
  .properties({
    value: {
      type: Degree,
    }
  })
)