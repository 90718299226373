import Component from 'sools-hedera/Component'
import template from './template.html'
import './style.scss'

export default (
	class ModelFiltersAny extends Component {

  onReady() {
    this.root = this.closest('model-filters')
  }
}
  .define({
    name: 'model-filters-any',
    template,
  })
)