import Base from '../Base'
import { virtuals } from './worker'
import mixer from 'sools-core/mixer'

export default (
	class Virtual extends mixer.extends([Base]) {

  static define(definition) {
    if (definition?.name) {
      virtuals.push(this)
    }
    return super.define(definition)
  }

  constructor(scope, variables, el, initialValue) {
    super()
    this.scope = scope
    this.variables = variables
    this.el = el
    this.initialValue = initialValue
  }

  getVariables() {
    return this.variables
  }

  preventInitialize() {
    return false
  }

  onReady() { }
}
  .define()

)