import appRouter from '../routing/router'
import template from './template.html'
import Root from 'sools-hedera/routing/Root'
import global from '../global'
import { wait } from 'sools-core/utils/promise'
import './style.scss'

const { navigator } = global

export default (
	class App extends Root {

  async start() {
    document.body.appendChild(this)
    this.router.use(appRouter)
    let start = new Date()
    navigator.use(this.router)
    await super.start()
    await navigator.start()
    await wait(Math.max(400 - (new Date() - start), 0))

    this.classList.add('ready')
  }
}
  .define({
    name: 'geptyro-io-app',
    template,
  })
)