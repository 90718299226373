import Component from 'sools-hedera/Component'
import template from './template.html'
import componentsService from '../../componentsService'
import './style.scss'

export default (
	class ModelSearch extends Component {
  constructor() {
    super()
  }

  onChange() {
    try {
      const query = eval(this.input.value)

    } catch (err) {
       
    }
  }

  template(result) {
    const componentType = componentsService.get(result.constructor, 'card')
    return new componentType(result)
  }
}
  .define({
    name: 'model-search',
    template,
  })
  .properties({
    results: 'any',
    open: 'any',
    length: 'any',
    type: 'any',
  })

)