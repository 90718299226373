import Virtual from "./Virtual"
import Templateable from '../mixins/Templateable.js'

class Template extends Virtual {
  static getType(ownerType) {
    if (!ownerType.prototype instanceof this.definition.template) {
      throw new Error('Types not matching')
    }

    const lastTemplate = ownerType.getLastTemplate()
    if (lastTemplate) {
      return lastTemplate
    }
    else {
      //return this.definition.template
    }

  }

}

Template.define({
  name: 'template',
})

Templateable.Template = Template

export default Template
