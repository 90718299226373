import template from './template.html'
import Component from '../../../Component'
import './style.scss'

export default (
	class ContextMenu extends Component {
  onInit() {
    this.contextMenu = this.closest('context-menu')
  }

  onSelected(){
    console.log('on selected')
    this.event('selected')
    this.contextMenu.open = false
  }
}
  .define({
    name: 'context-menu-item',
    template,
  })
)