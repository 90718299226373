import Destroyable from 'sools-core/mixins/Destroyable'
import mixer from 'sools-core/mixer'
import Equalable from 'sools-core/mixins/Equalable';
//import Transformable from './Transformable'

const SingleInstance = mixer.mixin([Destroyable, Equalable], (base) => {
  return class SingleInstance extends base {
    static parse(object, options = {}, context) {
      if(object == null){
        return object
      }
      if (!options.singleInstance) {
        return super.parse(object, options, context)
      }

      const instance = this.instances.getInstance(object)
      instance.set(object, options)
      return instance
    }
  }
})

export default SingleInstance