import State from './State.js'
import ArrayAssociation from '../types/ArrayAssociation.js'
const ignore = ['_id', '@']

const getState = (context, property) => {
  return (typeof property.state === 'function') ? property.state(context, property) : property.state || {}
}

export default (
	class ObjectState extends State {

  constructor(...args) {
    super(...args)
    this.update()
  }

  async onValueChanged() {
    this.update()
    if (this.value) {
      this.on(this.value, 'propertyChanged', this.b(this.onValuePropertyChanged))
    }
    await super.onValueChanged()
  }

  reset() {
    super.reset()

    Object.values(this.states).forEach((s) => {
      s.reset()
      const state = getState({ property: this.property }, s.property)
      Object.assign(s, state)
    })
  }

  async onValuePropertyChanged(property, value) {
    const state = this.states.find((s) => s.property === property)
    await state.set({
      value
    })
  }

  async onChildValueChanged(state) {
    if (this.value) {
      await this.value.set({
        [state.property.name]: state.value
      })
    }

    return super.onValueChanged()
  }

  update() {
    this.type = this.value?.constructor || this.property.type
    this.states = this.type.properties
      .filter((p) => {
        if (ignore.indexOf(p.name) !== -1) { return false }
        if (p.context === false) { return false }
        if (p.type.prototype instanceof ArrayAssociation) { return false }
        return true
      })
      .map((property) => {
        const stateType = property.type.stateType
        const value = this.value?.[property.name]
        const state = new stateType({
          property,
          value,
        })

        state.on('valueChanged', async () => {
          await this.onChildValueChanged(state)
        })
        return state
      })
  }

  async validate(updateChilds = false) {
    if (updateChilds) {
      for (const state of this.states) {
        await state.validate(true)
      }
    }
    return super.validate()
  }

  findFirstStateWithError() {
    for (const state of this.states) {
      if (state.errors.length) { return state }
      if (state.states) {
        const subState = state.findFirstStateWithError()
        if (subState) { return subState }
      }
    }
    return null
  }
}
  .define({
    name: 'ObjectState'
  })


)