import mixer from 'sools-core/mixer.js'
import Propertiable from 'sools-core/mixins/propertying/Propertiable.js'
import { Markdown } from 'sools-modeling/types'

export default mixer.mixin([Propertiable], (base) => {
  return class Wikiable extends base {

  }
})
  .define()
  .properties({
    wiki: {
      type: Markdown,
    }
  })