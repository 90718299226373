import Field from '../Field'
import template from './template.html'
import './style.scss'

export default (
	class BoolField extends Field {

}
  .define({
    name: 'bool-field',
    template,
  })
)