import Layout from 'sools-hedera/routing/Layout'
import template from './template.html'
import './style.scss'
import 'sools-modeling-hedera/components/SearchBar'
import global from 'sools-hedera/global'
const { auth, menu } = global
export default (
  class Main extends Layout {
    constructor() {
      super()
      this.menus = Array.from(Array(9))
    }
  }
    .define({
      name: 'layout-main',
      template,
    })
    .variables({
      auth,
      menu,
    })
    .properties({
      open: 'any',
    })

)