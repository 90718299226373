import mixer from 'sools-core/mixer'
import Propertiable from 'sools-core/mixins/propertying/Propertiable'
import BaseHoldable  from './BaseHoldable'

export default mixer.mixin([Propertiable], (base) => {
  return class Holder extends base {
    propertyChanged(property, value, oldValue) {
      if (oldValue && mixer.is(oldValue, BaseHoldable )) {
        oldValue.release(this)
      }
      if (value && mixer.is(value, BaseHoldable)) {
        value.hold(this)
      }
      return super.propertyChanged(property, value, oldValue)
    }
  }
})
