import mixer from 'sools-core/mixer.js'
import Real from '../Real.js'

export default (
	class Primitive extends mixer.extends(Real) {
  constructor() {
    throw new Error('Cannot instenciate')
  }

  static equals(value1, value2) {
    return value1 === value2
  }

  static parse(value) {
    return value
  }

  static toJSON(value) {
    return value
  }
}
  .define({
    name: 'primitive',
  })
)