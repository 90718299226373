
import './global'

// hedera
import 'sools-modeling-hedera/setup'
import 'sools-auth-hedera/setup'
import 'sools-core-hedera/setup'
import 'sools-hedera/setup'

import 'sools-hedera'
import 'sools-auth-hedera'
import 'sools-modeling-hedera'

import './style.scss'

import App from './App'
const app = new App()
app.start()