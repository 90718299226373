
import Base from '../Base'
import Value from '../values/Value'

export default (
	class Object extends Base {
  constructor(...args) {
    super(...args)
    this.on('propertyChanged', async (property, value) => {
      if (!this.parent) { return }
      await this.objectChanged(property, value)
    })
  }

  async objectChanged(property, value) {
    if (this.parent) {
      await this.parent.objectChanged(property, value)
    }

    return super.objectChanged(property, value)
  }
}
  .define({
    name: 'Object',
    abstract: true,
  })
)