import Propertiable from 'sools-core/mixins/propertying/Propertiable'
import mixer from 'sools-core/mixer'

export default (
	class ViewModel extends mixer.extends([Propertiable]) {
  constructor(values = {}) {
    super()
    Object.assign(this, values)
  }
}
)