import mixer from 'sools-core/mixer.js'
import Methods from './Methods.js'

export default mixer.mixin((base) => {
  return class Methodable extends base {
    static define(definition) {
      super.define(definition)
      this.methods = new Methods(this)
      return this
    }
  }
})

