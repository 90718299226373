import { Model } from 'sools-modeling/types'
import ModelComponent from '../ModelComponent'
import template from './template.html'
import Pageable from 'sools-modeling/mixins/Pageable'
import global from 'sools-hedera/global'
const { navigator } = global
import mixer from 'sools-core/mixer'
import './style.scss'

export default (
  class Card extends ModelComponent {

  }
    .define({
      name: 'model-card',
      template,
      type: 'card',
    })
    .variables({
      Pageable,
      navigator,
      mixer,
    })
    .register(Model, 'card')
)