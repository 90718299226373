import { Number } from 'sools-modeling/types'
import Size from './Size'

export default (
  class NumberSize extends Size {
    constructor(value) {
      super({ value })
    }
    
    toString() {
      return this.value?.toString()
    }
  }
    .define({
      name: 'Number',
    })
    .properties({
      value: {
        type: Number,
        state: {
          required: true,
        }
      }
    })
)