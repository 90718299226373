import mixer from 'sools-core/mixer.js'
import ArrayAssociation from './ArrayAssociation.js'
import setup from '../setup.js'
const { ownMany } = setup

export default (
  class OwnMany extends mixer.extends(ArrayAssociation, ownMany.before) {

    async innerLoad(context, paths = {}) {
      for (const model of this) {
        await model.load(context, paths)
      }
    }

    setPathsState(state, paths, err) {

    }
  }
    .define({
      name: 'ownMany',
    })
)