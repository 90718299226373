import Row from 'sools-modeling-hedera/components/Row'
import template from './template.html'
import { User } from 'sools-auth'
import './style.scss'

export default (
  class UserRow extends Row {

  }
    .define({
      name: 'user-row',
      template,
    })
    .register(User, 'row')
)