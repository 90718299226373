import { NumberSize, Size } from '../values/sizes'
import Display from './Display'

export default (
  class Flex extends Display {
    constructor(...args) {
      if (args.length === 1) {
        const [arg] = args
        if (typeof arg === 'object') {
          return super(arg)
        } else if (arg === 1) {
          return super({
            flexBasis: new NumberSize(0),
            flexGrow: new NumberSize(1),
            flexShrink: new NumberSize(1),
          })
        }
      } else if (args.length === 3) {
        return super({
          flexGrow: new NumberSize(args[0]),
          flexShrink: new NumberSize(args[1]),
          flexBasis: new NumberSize(args[2]),
        })
      }
      throw new Error('Not recognized')
    }
  }
    .define({
      name: 'Flex',
    })
    .properties({
      flexBasis: Size,
      flexShrink: Size,
      flexGrow: Size,
    })
)