import { Password, String } from 'sools-modeling/types'
import Field from '../../Field'
import template from './template.html'
import './style.scss'

const map = [
  [Password, 'password'],
  [String, 'text']
]

export default (
	class TextField extends Field {
  onInit() {
    this.type = map.find(([t]) => {
      const type = this.state.property.type
      return type === t || type.prototype instanceof t
    })[1]
  }

  setValue(value) {
    console.log('setting value', value)
    super.setValue(value)
    this.showValues = !value
  }
}
  .define({
    name: 'string-field',
    template,
  })
  .properties({
    showValues: 'any'
  })
)