import mixer from 'sools-core/mixer'

export default mixer.mixin((base) => {
  return class Holdable extends base {
    hold(reference) {
      throw new Error('Not implemented')
    }
    
    release(reference) {
      throw new Error('Not implemented')
    }
  }
})
