import template from './template.html'
import Component from '../../../Component'
import './style.scss'

export default (
  class ContextMenu extends Component {
    onInit() {
      this.listen(this.parentElement, 'contextmenu', this.b(this.onContextMenu))
    }

    onContextMenu(e) {
      if (this.contains(e.target)) { return }

      this.left = e.offsetX
      this.top = e.offsetY
      e.preventDefault()
      this.open = true
      this.focus()
    }
  }
    .define({
      name: 'context-menu',
      template,
    })
    .properties({
      open: 'any',
      left: 'any',
      top: 'any',
    })
)