import mixer from 'sools-core/mixer'
import Eventable from 'sools-core/mixins/Eventable'
import Destroyable from 'sools-core/mixins/Destroyable'

export default mixer.mixin([Destroyable, Eventable], (base) => {
  return class Transformable extends base {
    transform(to) {
      this.emit('transformed', [to])
      this.destroy()
    }
  }
})

