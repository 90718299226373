import ModelingObject from 'sools-modeling/types/Object'

class Base extends ModelingObject {
  get style() {
    return this.parent?.style || this
  }

  async setPropertyValue(property, value, options) {
    const finalValue = await super.setPropertyValue(property, value, options)
    if (finalValue instanceof Base) {
      Object.assign(finalValue, {
        property,
        parent: this,
      })
    }
    return finalValue
  }

  objectChanged(property, value) {
    return this.emit('objectChanged', [property, value])
  }
}
export default (Base
  .define({
    name: 'CssBase',
    abstract: true,
  })
)