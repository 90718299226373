import mixer from 'sools-core/mixer'
import Propertiable from 'sools-core/mixins/propertying/Propertiable'

class Context extends mixer.extends([Propertiable]) {
  constructor(values = {}) {
    super()
    Object.assign(this, values)
  }
}
const context = new Context()

export default context