import template from './template.html'
import BaseLoader from 'sools-hedera/components/loading/BaseLoader'
import './style.scss'

export default (
	class Loader extends BaseLoader {

}
  .define({
    name: 'app-loader',
    template,
  })
)