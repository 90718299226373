import mixer from 'sools-core/mixer.js'
import Propertiable from 'sools-core/mixins/propertying/Propertiable.js'

import Buildable from '../mixins/Buildable.js'
import Templateable from '../mixins/Templateable.js'
import Methodable from '../mixins/methoding/Methodable.js'
import utils from '../utils.js'
import Stateable from '../stating/Stateable.js'

export default mixer.mixin([Propertiable, Methodable, Buildable, Templateable, Stateable], (base) => {
  return class Any extends base {
    static getType(type) {
      return this
    }


    static sanitizeProperty(property) {
      utils.propertySanitizers.forEach((sanitizer) => sanitizer(property))
    }
  }
})
  .define({
    name: 'any',
  })
