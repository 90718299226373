import Array from './Array.js'
import setup from '../setup.js'
import mixer from 'sools-core/mixer.js'
import Destroyable from 'sools-core/mixins/Destroyable.js'

export default (
	class BaseModels extends mixer.extends(Array, [Destroyable, ...setup.baseModels.before]) {

}
  .define()

)