import './components'
import './form'
import mixer from 'sools-core/mixer'
import { onBindingDestroyed, onBindingGetProperty } from 'sools-hedera/workers/set/BindingFunction'
import BaseHoldable from 'sools-modeling-client/mixins/holding/BaseHoldable'

onBindingGetProperty((binding, value) => {
  if (!mixer.is(value, BaseHoldable)) { return }
  if (!binding.holdables) {
    binding.holdables = []
  }
  binding.holdables.push(value)
  value.hold(binding)
})

onBindingDestroyed((binding) => {
  binding.holdables?.forEach((h) => h.release(binding))
})