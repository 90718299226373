import mixer from 'sools-core/mixer'
import Propertiable from 'sools-core/mixins/propertying/Propertiable'

export default mixer.mixin([Propertiable], (base) => {
  return class extends base {

    reset(){
      super.reset()
      this.readOnly = false
      this.hidden = false
    }
  }
})
  .define()
  .properties({
    hidden: 'any',
    readOnly: 'any',
  })