import template from './template.html'
import global from 'sools-hedera/global'
const { components: { Interface } } = global
import context from 'sools-core-client/context'
import './style.scss'

export default (
  class UserMenu extends Interface {
    constructor() {
      super()
      this.isOpen = false
    }
  }
    .define({
      name: 'user-menu',
      template,
    })
    .properties({
      isOpen: 'any',
    })
    .variables({
      context,
    })
)