import mixer from 'sools-core/mixer.js'
import Propertiable from 'sools-core/mixins/propertying/Propertiable.js'
import State from './State.js'

export default mixer.mixin([Propertiable], (base) => {
  return class Stateable extends base {
    static stateType = State

    static state(arg) {
      if (arg.prototype instanceof State) {
        this.stateType = arg
      } else {
        this.stateType = arg(this.stateType.extends().define())
      }
      return this
    }
  }
})
  .define()