import './set'
import global from '../global'
const { workers, navigator } = global


workers.push({
  async process(scope, state) {
    const { node } = state
    if (node.nodeName !== 'A') { return }

    node.addEventListener('click', async (event) => {
      if (!node.href) { return }
      if (!node.href?.startsWith(location.origin)) { return }

      event.preventDefault()
      node.classList.add('loading')
      await navigator.navigate(node.href)
      node.classList.remove('loading')
    })
  }
})