import Collection from 'sools-modeling-client/Collection'

export default (
	class UserCollection extends Collection {
  async getMe() {
    const me = await this.apiRequest('/me', [])
    if (!me) { return null }

    const instance = this.type.parse(me, { singleInstance: true })
    instance.setLoadState(true)
    return instance
  }
}
)