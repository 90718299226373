import Base from './Base'
import Row from './Row'
import Array from 'sools-modeling/types/Array'

export default (
  class Column extends Base {

  }
    .define({
      name: 'Column',
    })
    .properties({
      rows: Array.of(Row)
    })
)