
import Base from '../Base'

export default (
	class Value extends Base {

  constructor(...args) {
    super(...args)
    this.on('propertyChanged', async () => {
      await this.objectChanged()
    })
  }

  async objectChanged() {
    await this.parent.objectChanged(this.property, this)
    return super.objectChanged(this.property, this)
  }

}
  .define({
    name: 'Value',
    abstract: true,
  })
)