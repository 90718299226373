import Service from 'sools-hedera/Service'
import Array from 'sools-core/types/Array'

export default (
	class MenuService extends Service {
  constructor() {
    super()
    this.links = new Array()
  }
}
  .define()
)