import template from './template.html'
import './style.scss'
import TabsDisplayer from '../TabsDisplayer'


export default (
  class Debug extends TabsDisplayer {
    constructor() {
      super()
      this.show = false
      this.height = 300
      this.listen(window, 'keyup', (e) => this.onKeyUp(e))
    }

    onKeyUp(e) {
      if (e.key !== 'F8') { return }
      this.show = !this.show
    }
  }
    .define({
      name: 'hedera-debug',
      template
    })
    .properties({
      show: 'any',
      height: 'any',
    })
)