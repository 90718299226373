import mixer from 'sools-core/mixer.js'
import Bool from '../primitives/Bool.js'
import String from '../primitives/strings/String.js'
import This from '../This.js'
import Any from '../Any.js'
import Indexable from '../../mixins/indexing/Indexable.js'

export default mixer.mixin([Any, Indexable], (base) => {
  return class ModelMixin extends base { }
})
  .define({
    name: 'modelMixin',
  })
  .indexes({
    id: {
      properties: ['_id'],
      unique: true,
      build: false,
    }
  })
  .properties({
    _id: {
      type: String,
      state: {
        disabled: true,
      }
    },
  })
  .methods({
    eq: [[This], Bool]
  })
  .state((State) =>
    State
      .validators(async (state) => {
        if (!state.filter.length) { return }

        const { value, root, filter } = state
        await value.load()
        const doesMatch = await match(root.context, value, [{ filter }])
        if (!doesMatch) {
          throw new Error('Value is not matching filter')
        }
      })
      .properties({
        filters: {
          default: () => [],
        },
      })
  )