import mixer from 'sools-core/mixer.js'
import ArrayAssociation from './ArrayAssociation.js'
import { hasMany } from '../setup.js'


export default (
	class VirtualArrayAssociation extends ArrayAssociation {
  toJSON(paths, context) {
    if (!paths) { return undefined }
    return super.toJSON(paths, context)
  }
}
  .define()
)