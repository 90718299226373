import mixer from 'sools-core/mixer'
import Destroyable from 'sools-core/mixins/Destroyable'

export default mixer.mixin([Destroyable], (base) => {
  const references = []
  return class Referenceable extends base {
    static references = references
    constructor(...args) {
      super(...args)
      references.push(this)
    }

    destroy() {
      const index = references.indexOf(this)
      references.splice(index, 1)
      return super.destroy()
    }
  }

})
  .define()

