import Rotations from './Rotations'
import Translations from './Translations'
import Value from './Value'

export default (
	class Transform extends Value {
  toString() {
    return [this.rotations, this.translations]
      .filter((o) => o)
      .map((o) => o.toString())
      .join(' ')
  }
}
  .define({
    name: 'Transform'
  })
  .properties({
    rotations: {
      type: Rotations,
    },
    translations: {
      type: Translations,
    }
  })
)