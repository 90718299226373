import Component from 'sools-hedera/Component'
import template from './template.html'
import Array from 'sools-core/types/Array'
import Any from './Any'
import './style.scss'

export default (
	class ModelFilters extends Component {

  onReady() {
    this.on('propertyChanged:type', this.b(this.onTypeChanged))
    this.refresh()
  }

  onTypeChanged() {
    this.refresh()
  }

  refresh() {
    this.anys = new Array(new Any())
  }

}
  .define({
    name: 'model-filters',
    template,
  })
  .properties({
    anys: 'any',
    type: 'any',
  })

)