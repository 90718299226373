import global from 'sools-hedera/global'
const { components: { Interface } } = global
import template from './template.html'
import './style.scss'

export default (
  class Field extends Interface {
    constructor(values = {}) {
      super()
      Object.assign(this, values)
    }

    async onInit() {
      this.on('propertyChanged:state', this.b(this.onStateChanged))
      await this.onStateChanged(this.state)
    }

    async onStateChanged(newState, oldState) {
      if (oldState) {
        this.off(oldState, 'propertyChanged:value', this.b(this.onValueChanged))
      }

      if (newState) {
        this.on(newState, 'propertyChanged:value', this.b(this.onValueChanged))
      }

      await this.onValueChanged()
    }

    onValueChanged() {

    }

    message(text) {
      const msg = {
        text,
        start: new Date()
      }
      this.state.messages.push(msg)
      setTimeout(() => {
        this.state.messages.tryRemove(msg)
      }, 5000)
    }

    async setValue(value) {
      this.touched = -1
      await this.state.set({
        value
      })
      this.event('changed', { field: this })
    }
  }
    .define({
      template,
    })
    .properties({
      header: 'any',
      state: 'any',
      touched: 'any',
    })
)