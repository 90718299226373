import authRouter from 'sools-auth-hedera/router'
import ModelingRouter from 'sools-modeling-hedera/routing/Router'
import Router from 'sools-hedera/routing/routers/Router'
import mainLayoutRouter from './mainLayoutRouter'
import emptyLayoutRouter from './emptyLayoutRouter'

emptyLayoutRouter.use(authRouter)
mainLayoutRouter.use(new ModelingRouter())

const router = new Router()

router.use(mainLayoutRouter)
router.use(emptyLayoutRouter)

router.use((req, res) => {
  res.navigate('/code-30000', true)
})

export default router
