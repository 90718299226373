import LayoutRouter from 'sools-hedera/routing/routers/LayoutRouter'
import Empty from '../layouts/Empty'

const router = new LayoutRouter({
  layout: Empty
})

router.route('/empty', async (req, res) => {
  await res.page(import('../pages/Empty'))
})

export default router