import Scope from '../Scope.js'
import handlers from './handlers'
import mixer from 'sools-core/mixer.js'
import Loadable from '../../mixins/Loadable.js'

class MemoryScope extends Scope {
  constructor(...args) {
    super(...args)
    this.handlers = handlers
  }
  async load(path) {
    await this.variables.this.value.load(path)
  }

  async innerGetProperty(property, source) {
    const value = source.value[property.name]
    if (mixer.is(property.type.prototype, Loadable)) {
      await source.value.load()
    }
    return value
  }
}

export default MemoryScope