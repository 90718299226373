import Component from '../../Component'

export default (
	class BaseLoader extends Component {

}
  .define()
  .properties({
    size: 'any',
  })

)