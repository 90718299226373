import Virtual from 'sools-hedera/virtualing/Virtual'
import Object from './objects/Object'

const forEachValue = (object, fn) => {

  object.constructor.properties.forEach((p) => {
    const value = object[p.name]
    if (value instanceof Object) {
      forEachValue(object[p.name], fn)
    } else {
      fn(p, value)
    }
  })
}

export default (
  class StyleVirtual extends Virtual {
    async onInit() {
      await this.bind('value', this.initialValue)
      this.on('propertyChanged:value', this.b(this.onValueChanged))
      this.onValueChanged()
    }

    onValueChanged() {
      this.update()
      const values = this.getValues()
      for (const v of values) {
        this.on(v, 'objectChanged', (property, value) => {
          this.applyValue(property, value)
        })
      }
    }

    applyValue(property, value) {

      const stringValue = value?.toString() || null
      this.el.style[property.name] = stringValue
    }

    getValues() {
      let { value: values } = this
      if (!Array.isArray(values)) {
        values = [values]
      }
      return values
        .filter((o) => o)
    }

    update() {
      if (!this.value) { return }

      const values = this.getValues()
      for (const v of values) {
        forEachValue(v, (property, value) => {
          this.applyValue(property, value)
        })
      }

    }
  }
    .define({
      name: 'style'
    })
    .properties({
      value: 'any'
    })
)