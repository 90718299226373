import Router from 'sools-hedera/routing/routers/Router'
import context from 'sools-core-client/context'
import global from 'sools-hedera/global'
const { auth } = global

const router = new Router()

const notConnected = (req, res, next) => {
  if (context.me) {
    return res.navigate('/')
  }
  return next()
}

router.route('/login', notConnected, (req, res) => {
  res.page(import('./pages/Login'))
})

router.route('/signup', notConnected, (req, res) => {
  res.page(import('./pages/Signup'))
})

router.route('/logout', (req, res) => {
  auth.logout()
  return res.navigate('/')
})

export default router