import template from './template.html'
import Component from 'sools-hedera/Component'
import Array from 'sools-core/types/Array'
import './style.scss'
import './Tab'

export default (
  class TabsDisplayer extends Component {

    onInit() {
      this.focus(this.tabs?.[0])
    }

    focus(tab) {
      this.currentTab = tab
    }
  }
    .define({
      name: 'tabs-displayer',
      template,
    })
    .properties({
      currentTab: 'any',
    })
)