import Primitive from '../Primitive.js'
import Bool from '../Bool.js'
import Object from '../../Object.js'

class String extends Primitive {
  static parse(value, owner, property) {
    if (value == null) { return value }

    if (typeof value !== 'string') {
      throw new Error(`Property ${property.name} has to be a string, received ${value}`)
    }
    if (property.values && property.values.indexOf(value) === -1) {
      throw new Error(`Property ${property.name} with value ${value} does not match values: ${property.values.join(',')}`)
    }
    return super.parse(value, owner, property)
  }
}

String
  .define({
    name: 'String',
  })
  .methods({
    match: [[String], Bool],
    toUpperCase: [[], String]
  })
  .state((State) =>
    State
      .properties({
        values: 'any',
        regex: 'any'
      })
      .validators(({ value, values }) => {
        if (!values) { return }

        if (values.indexOf(value) === -1) {
          throw new Error(`Valut not recognized`)
        }
      })
      .validators(({ regex, value }) => {
        if (!regex) { return }

        if (value.matches(regex)) {
          throw new Error('Regex not matching')
        }
      })
  )

Object.properties({
  '@type': String
})


export default String