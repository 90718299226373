import Value from '../Value'

export default (
	class Angle extends Value {

}
  .define({
    name: 'Angle',
    abstract: true,
  })
)