import Array from 'sools-core/types/Array.js'
import Propertiable from 'sools-core/mixins/propertying/Propertiable.js'
import mixer from 'sools-core/mixer.js'
import Bindeable from 'sools-core/mixins/Bindeable.js'
import setup from '../setup.js'
import Validators from './Validators.js'

class BaseState extends mixer.extends([Propertiable, Bindeable]) {

  static define(definition) {
    super.define(definition)
    this.validators = new Validators(this)
    return this
  }

  reset() {
    this.constructor.properties.forEach((p) => {
      let defaultValue = null
      if (p.default) {
        defaultValue = p.default()
      } else if (p.type.default) {
        defaultValue = p.type.default()
      }

      this[p.name] = defaultValue
    })
  }

  constructor(values) {
    super()
    Object.assign(this, values)
    this.on('propertyChanged:value', this.b(this.onValueChanged))
  }

  async onValueChanged() {
    await this.validate()
    await this.emit('valueChanged')
  }

  async validate() {
    const validators = [...this.constructor.validators]

    try {
      for (const validator of validators) {
        const shouldContinue = await validator(this)
        if (shouldContinue === false) { return }
      }
    } catch (err) {
      this.errors.push(err.message)
    }
  }
}

BaseState
  .define()
  .properties({
    value: 'any',
    disabled: {
      default: () => false,
    },
    required: {
      default: () => false,
    },
    messages: {
      default: () => new Array()
    },
    errors: {
      default: new Array()
    },
  })
  .validators((state) => {
    const { required, value } = state
    if (required && value == null) {
      throw new Error('This field is required')
    }

    return required
  })

export default (
	class State extends mixer.extends(BaseState, setup.state) { }
  .define()

)