import mixer from 'sools-core/mixer.js'
import Abstractable from 'sools-core/mixins/Abstractable.js'
import Equalable from 'sools-core/mixins/Equalable.js'
import Any from './Any.js'

export default (
  class Real extends mixer.extends([Any, Abstractable, Equalable]) {

  }
    .define({
      name: 'real',
      abstract: true,
    })
)