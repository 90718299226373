import Tree from 'sools-core/types/Tree.js'
import Mixin from 'sools-core/Mixin.js'

export default (
	class Validators extends Tree {
  constructor(owner) {
    super()
    this.owner = owner
    owner.definition.parents
      .filter((p) => p.validators)
      .forEach((p) => { this.push(p.validators) })
  }

  call(...args) {
    super.call(...args)
    return this.owner
  }

  shouldIterateTree(it, tree) {
    const shouldIterateTree = !(this.owner.prototype instanceof Mixin) || !it.from || !(tree instanceof Validators)
    return shouldIterateTree
  }
}
)