import Real from './Real.js'
import Template from './Template.js'
import Any from './Any.js'
const template = Template.of(Any)

export default (
	class Map extends Real {

}
  .define({
    name: 'Map',
    template,
  })

)