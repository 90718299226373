import mixer from 'sools-core/mixer'
import Propertiable from 'sools-core/mixins/propertying/Propertiable'

export default (
	class It extends mixer.extends([Propertiable]) {
  constructor(values) {
    super()
    Object.assign(this, values)
  }

  destroy() {
    this.scope.destroy()
    this.element.remove()
  }
}
  .define()
  .properties({
    index: 'number',
  })

)