import Virtual from './Virtual.js'
import Bool from './primitives/Bool.js'
import Any from './Any.js'
import Array from './Array.js'
import Dynamic from './Dynamic.js'
import Map from './Map.js'
import Scope from './Scope.js'
import { getCommonAncestor } from 'sools-core/utils/proto.js'

class Global extends Virtual {
  getType() {
    return this
  }
}

Global
  .define({
    name: 'Global',
  })
  .methods({
    and: [[{ type: Array.of(Any), spread: true }], Bool],
    or: [[{ type: Array.of(Any), spread: true }], Bool],
    if: [[Any, Any, Any], Dynamic.with((args) => getCommonAncestor(args[1].type, args[2].type))],
    let: [[Map.of(Any), Scope], Dynamic.with((args) => args[1].type)]
  })

export default Global