import Field from '../../Field'
import template from './template.html'
import './style.scss'

export default (
	class MarkdownField extends Field {

}
  .define({
    name: 'markdown-field',
    template,
  })
)