import { Model, String } from 'sools-modeling/types'
import mixer from 'sools-core/mixer.js'
import Pageable from 'sools-modeling/mixins/Pageable.js'
import Wikiable from 'sools-wiki/mixins/Wikiable.js'
import setup from './setup.js'

export default (
	class User extends mixer.extends(Model, [Pageable, Wikiable, ...setup.user]) {
  toString() {
    return this.username
  }
}
  .define({
    name: 'user',
    pluralName: 'users',
    root: true,
    codeField: 'username',
    searchField: 'username',
    titleField: 'username',
  })
  .indexes({
    username: {
      properties: ['username'],
      unique: true,
    },
    email: {
      properties: ['email'],
      unique: true,
    }
  })
  .properties({
    username: {
      type: String,
      state: {
        required: true,
      }
    },
    email: {
      type: String,
      state: {
        required: true,
      }
    },
  })
)