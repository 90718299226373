import Field from '../Field'
import template from './template.html'
import './style.scss'

export default (
	class NumberField extends Field {

}
  .define({
    name: 'number-field',
    template,
  })
  .properties({
    step: 'any',
  })

)