import ObjectState from './ObjectState.js'

export default (
	class RootModelState extends ObjectState {

  async applyLogics() {
    this.reset()
    for (const controller of this.property.type.controllers) {
      const logic = !this.isEdit ? controller.create?.logic : controller.update?.logic
      if (logic) {
        await logic(this.context, this.states, this.oldValue)
      }
    }
  }

  async validate() {
    await super.validate()

  }
}
  .define()
  .validators(async (state) => {
    const { property, states, value, context } = state
    const type = property.type
    for (const index of type.indexes) {
      if (index.build === false || !index.unique) { continue }

      const values = index.properties.reduce((acc, propertyName) => {
        acc[propertyName] = states[propertyName].value
        return acc
      }, {})

      const filledValues = Object.values(values).filter((v) => v != null)
      if (filledValues.length !== index.properties.length) {
        continue
      }
      const filters = Object.entries(values)
        .map(([k, v]) => ({
          $eq: [`$${k}`, v]
        }))

      if (value._id) {
        filters.push({
          $neq: ['$_id', value._id]
        })
      }

      const existingModel = await type.collection.findOne(context, filters, {
        type: index.owner.definition.name,
      })

      if (!existingModel) { continue }

      if (index.properties.length > 1) {
        errors.push(`Values combinaison on fields '${index.properties.join(', ')}' is not available`)
      } else {
        const state = states[index.properties[0]]
        state.errors.push('This value is already taken')
      }
    }
  })
)