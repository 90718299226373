import Value from './Value'
import Angle from './angles/Angle'

export default (
	class Rotations extends Value {
  toString() {
    return ['x', 'y', 'z']
      .map((angle) => ({ angle, value: this[angle] }))
      .filter(({ value }) => value)
      .map(({ angle, value }) => `rotate${angle}(${value.toString()})`)
      .join(' ')
  }
}
  .define({
    name: 'Rotations'
  })
  .properties({
    x: {
      type: Angle
    },
    y: {
      type: Angle
    },
    z: {
      type: Angle
    }
  })
)