import Field from '../Field'
import template from './template.html'
import './style.scss'

export default (
	class DateField extends Field {
  constructor() {
    super()
    this.value = ''
  }



}
  .define({
    name: 'date-field',
    template,
  })

)