import global from './global.js'
import real from './real.js'
import primitive from './primitive.js'
import bool from './bool.js'
import string from './string.js'
import object from './object.js'
import _function from './function.js'
import array from './array.js'
import branch from './branch.js'
import model from './model.js'
import _type from './type.js'
import hasMany from './hasMany.js'

export default [
  global,
  real,
  primitive,
  bool,
  string,
  object,
  _function,
  array,
  branch,
  model,
  _type,
  hasMany
]
