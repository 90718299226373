import mixer from 'sools-core/mixer'
import BaseHoldable from './BaseHoldable'

export default mixer.mixin((base) => {
  return class ArrayHolder extends base {
    changed() {
      if (this.copy) {
        this.copy.forEach((o) => {
          if (mixer.is(o, BaseHoldable)) {
            o.release(this)
          }
        })
      }
      this.copy = [...this]
      this.copy.forEach((o) => {
        if (mixer.is(o, BaseHoldable)) {
          o.hold(this)
        }
      })
      return super.changed()
    }
  }
})
