import { Number } from "sools-modeling/types"
import Object from '../objects/Object'
import Transform from "../values/Transform"

export default (
	class Display extends Object {

}
  .define({
    name: 'Display',
    abstract: true,
  })
  .properties({
    zIndex: {
      type: Number,
      state: {
        min: 0,
      }
    },
    transform: {
      type: Transform
    }
  })
)