import Layout from 'sools-hedera/routing/Layout'
import template from './template.html'
import './style.scss'

export default (
	class Empty extends Layout {

}.define({
  name: 'empty-layout',
  template,
})
)