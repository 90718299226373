import Flex from '../../../style/displays/Flex'
import Base from './Base'

export default (
  class Cell extends Base {

  }
    .define({
      name: 'Cell',
    })
    .properties({
      style: Flex
    })
)