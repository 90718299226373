import { HasMany } from 'sools-modeling/types'
import User from './User.js'
import Identity from './Identity.js'
export { User }
export { Identity }

Identity.properties({
  user: {
    type: User,
  }
})

User.properties({
  identities: {
    type: HasMany.of(Identity)
  }
})

