import { Number } from 'sools-modeling/types'
import Size from './Size'

export default (
  class PercentageSize extends Size {

    constructor(value) {
      super({ value })
    }
    
    toString() {
      return `${this.value}%`
    }
  }
    .define({
      name: '%',
    })
    .properties({
      value: {
        type: Number,
        state: {
          required: true,
        }
      }
    })
)