import { Model, String } from 'sools-modeling/types'
import mixer from 'sools-core/mixer.js'

export default (
	class Identity extends mixer.extends(Model, []) {

}
  .define({
    name: 'identity',
    pluralName: 'identities',
    root: true,
  })
  .indexes({
    username: {
      properties: ['username'],
      unique: true,
    }
  })
  .properties({
    provider: {
      type: String,
      state: {
        required: true,
      }
    },
  })

)