import { Number } from 'sools-modeling/types'
import Value from '../Value'
import Angle from './Angle'

export default (
	class Radius extends Angle {
  toString() {
    return `${this.value}rad`
  }
}
  .define({
    name: 'Radius',
  })
  .properties({
    value: {
      type: Number,
    }
  })
)