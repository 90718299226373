import Teemo from './Teemo/assets/Teemo_27.jpg'
import Steam from '../assets/Steam.webp'
import Youtube from '../assets/youtube.jpg'
import Github from '../assets/github.png'
import LoL from '../assets/lol.jpg'

export default [
  {
    target: '_blank',
    style: {
      backgroundColor: '#013a60',
    },
    url: 'https://steamcommunity.com/id/kanax-stratz/',
    image: Steam,
  },
  {
    target: '_blank',
    style: {
      backgroundColor: '#000000',
    },
    url: 'https://www.youtube.com/@its-geptyro',
    image: Youtube,
  },
  {
    target: '_blank',
    url: 'https://github.com/kanaxz',
    style: {
      backgroundColor: '#e1e1e1',
    },
    image: Github,
  },
  {
    target: '_blank',
    url: 'https://www.leagueofgraphs.com/summoner/euw/GEPTYRO-FTW',
    style: {
      backgroundSize: 'cover'
    },
    image: LoL,
  }
]


