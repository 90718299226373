import global from 'sools-hedera/global'
const { components: { Interface } } = global
import template from './template.html'
import Propertiable from 'sools-core/mixins/propertying/Propertiable'
import mixer from 'sools-core/mixer'
import Pageable from 'sools-modeling/mixins/Pageable'
import Mixin from 'sools-core/Mixin'

import './style.scss'

class SearchableResults extends mixer.extends([Propertiable]) {
  constructor(type) {
    super()
    this.type = type
  }

  async search(key) {
    this.error = false
    this.loading = true
    const { searchField } = this.type.definitions.find((d) => d.searchField)
    try {
      this.results = await this.type.collection.find([{
        $match: [`$${searchField}`, key]
      }], {
        type: this.type.definition.name,
        limit: 3
      })
    } catch (e) {
      console.error(e)
      this.error = e.message
    } finally {
      this.loading = false
    }
  }
}

SearchableResults
  .define()
  .properties({
    loading: 'bool',
    results: 'any',
    error: 'bool',
  })

const childs = Pageable
  .getAllChilds()
  .filter((c) => !c.definition.abstract && !(c.prototype instanceof Mixin))

export default (
  class Search extends Interface {
    constructor() {
      super()
      this.searchables = childs.map((type) => {
        return new SearchableResults(type)
      })
      this.first = true
    }

    start() {
      this.open = true
      if (this.first) {
        this.search()
        this.first = false
      }

    }

    stop() {
      this.open = false
      this.input.blur()
    }

    async search() {
      const promises = this.searchables.map(async (searchable) => {
        await searchable.search(this.input.value)
      })
      await Promise.all(promises)
    }

    selectSuggestion(suggestion) {
      suggestion.click()
      this.stop()
    }

    empty() {
      this.input.value = ''
      this.search()
    }
  }
    .define({
      name: 'search-bar',
      template,
    })
    .properties({
      results: 'any',
      open: 'any',
      length: 'any',
    })
)